import Text from 'shared/components/Text/Text';
import styled from 'styled-components';

export const RegistrationEmailFormStyled = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const BodyText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textSecondaryGrey90};
`;
