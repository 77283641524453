import React, { useState } from 'react';
import { resolvePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { useSendAccessCode } from 'api/account';
import { pages } from 'pages';
import { toast } from 'shared/components/Toast/Toast';
import { Header } from 'modules/auth/components/Header/Header';
import {
  RegistrationEmailForm,
  RegistrationEmailFormInput,
} from 'modules/auth/components/RegistrationEmailForm/RegistrationEmailForm';
import { AuthBox, AuthWrapper, LinkStyled, LoginFooter } from './Registration.styles';

export function Registration() {
  const [verificationEmail, setVerificationEmail] = useState('');
  const [showPinForm, setShowPinForm] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();

  const { mutate } = useSendAccessCode({
    onSuccess: () => {
      setShowPinForm(true);
    },
    onError: () => {
      toast({
        type: 'error',
        title: t('toast.error'),
        message: t('registration.invalidEmail'),
        theme,
      });
    },
  });

  const handleSubmitEmailForm = ({ email }: RegistrationEmailFormInput) => {
    setVerificationEmail(email);
    mutate({ email });
  };

  return (
    <AuthWrapper>
      <AuthBox>
        <Header
          title={t('registration.tellUsYour')}
          titleBold={t('registration.emailAddress')}
          showLogo
        />

        {showPinForm && verificationEmail ? null : (
          <RegistrationEmailForm onSubmitForm={handleSubmitEmailForm} />
        )}
      </AuthBox>
      <LoginFooter>
        {t('claimAccount.alreadyHaveAnAccount')}{' '}
        <LinkStyled to={resolvePath(`/${pages.LOGIN}`)}>{t('loginScreen.logInHere')}</LinkStyled>
      </LoginFooter>
    </AuthWrapper>
  );
}

export default Registration;
