import React from 'react';
import { Navigate, RouteObject } from 'react-router';

import pages from 'pages';
import { CheckEmail } from 'modules/auth/screens/CheckEmail/CheckEmail';
import { ClaimAccount } from 'modules/auth/screens/ClaimAccount/ClaimAccount';
import { CreatePassword } from 'modules/auth/screens/CreatePassword/CreatePassword';
import { Login } from 'modules/auth/screens/Login/Login';
import { ResetPassword } from 'modules/auth/screens/ResetPassword/ResetPassword';
import { Registration } from 'modules/auth/screens/Registration/Registration';
import { ThemeLayout } from 'layouts/ThemeLayout';
import { Layout } from 'shared/components/Layout/Layout';

export const Auth: RouteObject[] = [
  {
    element: (
      <ThemeLayout>
        <Layout hasFooter isAuthScreen />
      </ThemeLayout>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={`/${pages.LOGIN}`} replace />,
      },
      {
        path: pages.LOGIN,
        element: <Login />,
      },
      { path: pages.FORGOT_PASSWORD, element: <ResetPassword /> },
      { path: pages.RESET_PASSWORD, element: <CreatePassword /> },
      { path: pages.CHECK_EMAIL, element: <CheckEmail /> },
      { path: pages.CLAIM_ACCOUNT, element: <ClaimAccount /> },
      { path: pages.REGISTRATION, element: <Registration /> },
    ],
  },
];

export default Auth;
