import React, { useEffect } from 'react';
import { Navigate, Route, Routes, resolvePath } from 'react-router-dom';
import { useUser } from 'hooks/useUser';
import { usePageName } from 'hooks/usePageName';
import { pages } from 'pages';

import ProtectedRoute from 'legacy/ProtectedRoute';
import ResetPassword from 'legacy/views/auth/ResetPassword';
import ClaimAccountLegacy from 'legacy/views/auth/ClaimAccount';
import Login from 'legacy/views/auth/Login';
import ForgotPassword from 'legacy/views/auth/ForgotPassword';
import { isHomeowner } from 'legacy/utils';
import LayoutLegacy from 'legacy/views/Layout';

import { Account } from 'modules/account/screens/Account';
import { Status } from 'modules/status/screens/Status';
import { Support } from 'modules/support/screens/Support';
import { Layout } from 'shared/components/Layout/Layout';
import { Login as LoginV2 } from 'modules/auth/screens/Login/Login';
import { ResetPassword as ResetPasswordV2 } from 'modules/auth/screens/ResetPassword/ResetPassword';
import { CreatePassword } from 'modules/auth/screens/CreatePassword/CreatePassword';
import { CheckEmail } from 'modules/auth/screens/CheckEmail/CheckEmail';
import { ClaimAccount } from 'modules/auth/screens/ClaimAccount/ClaimAccount';
import { Registration } from 'modules/auth/screens/Registration/Registration';
import OverviewIcon from 'shared/components/icons/OverviewIcon';
import SystemIcon from 'shared/components/icons/SystemIcon';
import BillingIcon from 'shared/components/icons/BillingIcon';
import SupportIcon from 'shared/components/icons/SupportIcon';
import { AdminContextProvider } from 'contexts/AdminContext';
import { ErrorFallback } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { FlagName } from 'api/types';
import useFeatureFlags from 'hooks/useFeatureFlags';
import StatusLegacy from 'legacy/views/status/Status';
import { Dashboard } from './Dashboard';
import { Admin } from './Admin';
import { getDashboardRedirectPath } from './utils/getDashboardRedirectPath';

export function AppDependentRoutes() {
  const { userResult, selectedAccount } = useUser();
  const { currentPage } = usePageName();

  const { hasDashboardAccess } = getDashboardRedirectPath(
    userResult?.user || undefined,
    selectedAccount,
  );

  useEffect(() => {
    const chatButton: HTMLElement | null = document.querySelector('.ie-div-position-customer-chat');
    if (chatButton) {
      if (!userResult?.user) {
        chatButton.style.display = 'none';
      } else {
        chatButton.style.display = 'block';
      }
    }
  }, [userResult]);

  const {
    loading,
    flags: { [FlagName.webV2_1]: refreshV2Flag, [FlagName.statusV2]: statusV2Flag },
  } = useFeatureFlags();

  if (loading) return null;

  const overviewIcon = () => <OverviewIcon filled={currentPage === 'OVERVIEW'} />;
  const systemIcon = () => <SystemIcon filled={currentPage === 'SYSTEM'} />;
  const billingIcon = () => <BillingIcon filled={currentPage === 'BILLING'} />;
  const supportIcon = () => <SupportIcon filled={currentPage === 'SUPPORT'} />;

  const overviewMenuOptions = [
    {
      label: 'Overview',
      link: resolvePath(pages.OVERVIEW, `/${pages.DASHBOARD}`),
      icon: overviewIcon,
    },
    ...(hasDashboardAccess
      ? [
          {
            label: 'System',
            link: resolvePath(pages.SYSTEM, `/${pages.DASHBOARD}`),
            icon: systemIcon,
          },
          {
            label: 'Billing',
            link: resolvePath(pages.BILLING, `/${pages.DASHBOARD}`),
            icon: billingIcon,
          },
        ]
      : []),
    {
      label: 'Support',
      link: !hasDashboardAccess
        ? resolvePath(`/${pages.SUPPORT}`)
        : resolvePath(pages.SUPPORT, `/${pages.DASHBOARD}`),
      icon: supportIcon,
    },
  ];

  return (
    <Routes>
      <Route
        element={
          <ProtectedRoute
            isAllowed={Boolean(userResult?.user)}
            redirectPath={pages.LOGIN}
            hasSession
            isLoading={userResult?.loading}
          />
        }
      >
        <Route element={<Layout hasNav menuOptions={overviewMenuOptions} />}>
          <Route index element={<Navigate to={pages.DASHBOARD} />} />
          <Route path={`${pages.DASHBOARD}/*`} element={<Dashboard />} />
          <Route path={pages.ACCOUNT} element={<Account />} />
          <Route path={pages.STATUS} element={statusV2Flag ? <Status /> : <StatusLegacy />} />
          {!hasDashboardAccess ? <Route path={pages.SUPPORT} element={<Support />} /> : null}
          <Route path="*" element={<ErrorFallback is404 />} />
        </Route>

        <Route element={<LayoutLegacy hasNav hasFooter />}>
          <Route
            path={`${pages.ADMIN}/*`}
            element={
              <AdminContextProvider>
                <Admin />
              </AdminContextProvider>
            }
          />
        </Route>
      </Route>

      <Route
        element={
          <ProtectedRoute
            isAllowed={!userResult?.user}
            redirectPath={
              isHomeowner(userResult?.user?.role) ? `/${pages.DASHBOARD}` : `/${pages.ADMIN}`
            }
            isLoading={userResult?.loading}
          />
        }
      >
        {refreshV2Flag ? (
          <Route element={<Layout hasFooter isAuthScreen />}>
            <Route path={pages.LOGIN} element={<LoginV2 />} />
            <Route path={pages.FORGOT_PASSWORD} element={<ResetPasswordV2 />} />
            <Route path={pages.RESET_PASSWORD} element={<CreatePassword />} />
            <Route path={pages.CHECK_EMAIL} element={<CheckEmail />} />
            <Route path={pages.CLAIM_ACCOUNT} element={<ClaimAccount />} />
            <Route path={pages.REGISTRATION} element={<Registration />} />
          </Route>
        ) : (
          <Route element={<LayoutLegacy accountView hasNav hasFooter />}>
            <Route path={pages.LOGIN} element={<Login />} />
            <Route path={pages.FORGOT_PASSWORD} element={<ForgotPassword />} />
            <Route path={pages.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={pages.CLAIM_ACCOUNT} element={<ClaimAccountLegacy />} />
          </Route>
        )}
      </Route>

      <Route path="*" element={<ErrorFallback is404 />} />
    </Routes>
  );
}

export default AppDependentRoutes;
